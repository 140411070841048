import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendMessage } from '../actions/other.actions';
import { successToast } from '../utils/toast';

const Contact = () => {
    const dispatch = useDispatch();

    const [fin, setFin] = useState(0);
    const [lear, setLearn] = useState(0);

    const formik = useFormik({
        initialValues: {
            email : '',
            name : '',
            phone : '',
            currInvestment : 'yes',
            learning : 'yes',
        },
        onSubmit: values => {
            console.log(values);
            dispatch(sendMessage(values,formik));
        }
    });
  return (
    <div>
        <div className="contact df-jcsb" id="contact">
        <div className="contact-details">
          <div className="cd-heading text-white">
            <h2>
              We’d love to hear <br /> from you 😁
            </h2>
            <p>Fill out the form and you will hear from us shortly.</p>
          </div>
          <div className="details-card">
            {/* <p>
              Phone:{" "}
              <a href="tel:+918077412041" className="text-blue">
                +91 8077412041
              </a>
            </p> */}
            <p>
              Email:{" "}
              <a
                href="mailto:vedicinvestmentadvisory@gmail.com"
                className="text-blue"
              >
                &nbsp;vedicinvestmentadvisory@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="contact-form">
          <input placeholder="Name" 
            id = "name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <input placeholder="Email" 
            id = "email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <input placeholder="Phone" 
            id = "phone"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          <h3>Financial Market knowledge </h3>
          <p>
            Do you currently have any type of investment including stocks, bond
            mutual fund, retirement account?
          </p>
          <div className="df-jcse">
            <div className={fin == 0 ? "yes selected" :"yes"} onClick={()=>{setFin(0);formik.setFieldValue('currInvestment','yes')}}>
                Yes
            </div>
            <div  className={fin == 1 ? "no selected" :"no"} onClick={()=>{setFin(1);formik.setFieldValue('currInvestment','no')}}>
                No
            </div>
          </div>
          {/* <div className="df-jcse">
            <label for="yesCur">Yes</label>
            <input type="radio" id="yesCur" name="fin" value="Yes" />
            <label for="noCur">No</label>
            <input type="radio" id="noCur" name="fin" value="No" />
          </div> */}
          <br />
          <p>
            Would you be interested in learning more about investing for
            yourself?
          </p>
          <div className="df-jcse">
            <div className={lear == 0 ? "yes selected" :"yes"} onClick={()=>{setLearn(0);formik.setFieldValue('learning','yes')}}>
                Yes
            </div>
            <div  className={lear == 1 ? "no selected" :"no"} onClick={()=>{setLearn(0);formik.setFieldValue('learning','yes')}}>
                No
            </div>
          </div>
          <br />
          <button className="button" onClick={formik.handleSubmit} type="submit">Submit</button>
        </div>
      </div>
    </div>
  )
}

export default Contact