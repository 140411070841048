import * as actionTypes from '../reducers/types';
import axios from '../helpers/axios';
import { errorToast, successToast } from '../utils/toast';
import { Formik } from 'formik';

export const sendMessage = (data,formik) => async (dispatch) => {
    try{
        const res = await axios.post('/email/send', data);
        if(res.data.status){
            successToast('Message Sent Successfully');
            formik.resetForm();
        }
        else{
            errorToast(res.data.message);
        }
    }
    catch(err){
        console.log(err);
    }
}
