import React, { useState } from "react";
import Navbar from "../component/Navbar";
import TWIN from "../img/twin-phn.svg";
import Heights from "../img/heights.svg";
import Vector from "../img/vector.svg";
import Vedic from "../img/iphone.svg";
import Mission from "../img/mission.svg";
import LIC from "../img/LIC.svg";
import Upstox from "../img/upstox.svg";
import AngelOne from "../img/angel.svg";
import Star from "../img/star.svg";
import Digit from "../img/digit.svg";
import Twitter from "../img/twitter.svg";
import Instagram from "../img/instagram.png";
import Telegram from "../img/telegram.svg";
import Footer from "../component/Footer";
import Lottie from "react-lottie";
import * as animationData from "../animation/header.json";
import Contact from "../component/Contact";

const Index = () => {
  const [tab, setTab] = useState("capital");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


	const scroll = (id) => {
		document.getElementById(id).scrollIntoView({ behavior: "smooth" });
	}
  
  return (
    <div className="page-wrapper">
      <Navbar />
      {/* <div className="first-comp" id="about">
        <div className="first-comp-heading">
          <h1>
            {" "}
            Invest For <br /> The Future
          </h1>
          <p>
            We VEDIC INVESTMENT ADVISORY Financial Consultancy firm at
            present having more than 1000+ satisfied clients. We will help
            clients to achieve their Financial goals effectively and smart
            investment planning. We are certified Wealth Manager, Mutual Fund
            and Insurance consultants recognized by the NSE (National Stock
            Exchange), AMFI (Association of Mutual Fund of India).
          </p>
        </div>
        <div className="first-img">
          <img src={TWIN} alt="twin" />
        </div>
      </div> */}
      <div className="landing-component">
        <div className="landing-component-left">
          <h1>
            Invest For <br className="remove-br" />
            The Future
          </h1>
          <p>
            We VEDIC INVESTMENT ADVISORY Financial Consultancy firm at present
            having more than 1000+ satisfied clients. We will help clients to
            achieve their Financial goals effectively and smart investment
            planning. We are certified Wealth Manager, Mutual Fund and Insurance
            consultants recognized by the NSE (National Stock Exchange), AMFI
            (Association of Mutual Fund of India).
          </p>
          <button className="button" onClick={()=>scroll("contact")}> Get Started</button>
        </div>
        <div className="landing-component-right">
          <Lottie options={defaultOptions} height={800} width={700} />
        </div>
      </div>
      <div className="services" id="services">
        <div className="left-service">
          <h3>
            Invest your <br />{" "}
            <span className="money-smartly">Money smartly</span>
          </h3>
          <p>
            {/* Purchase of any Financial Products without consulting a financial
            Planner is like taking medicine without prescription . */}
          </p>
          <br />
          <p>
            We offer our expert advice for choosing the best investment products
            which will lead to your Financial Freedom.
          </p>
          <div className="white-part">
            <img src={Vector} alt="Vector" />
          </div>
          <img src={Heights} alt="heights" className="heights-img" />
        </div>
        <div className="right-service">
          <div className="heading">
            <h1 className="select-service">
              Select <span>Services</span>
            </h1>
            <p className="still-confuse">
              Still Confused 🧐
              <br />{" "}
              <a href="#contact" className="text-blue">
                Contact Us
              </a>
            </p>
          </div>
          <div className="service-info">
            <div className="service-info-left">
              <h2 className="text-blue">Services</h2>
              <ul className="services-ul">
                <li>
                  <a href="javascript:;" onClick={() => setTab("capital")}>
                    Capital Market Service
                  </a>
                </li>
                <li>
                  <a href="javascript:;" onClick={() => setTab("insurance")}>
                    Insurance Service
                  </a>
                </li>
                <li>
                  <a href="javascript:;" onClick={() => setTab("mutual")}>
                    Mutual Fund Service
                  </a>
                </li>
                <li>
                  <a href="javascript:;" onClick={() => setTab("edu")}>
                    Education & Learning
                  </a>
                </li>
                <li>
                  <a href="javascript:;" onClick={() => setTab("other")}>
                    Other Services
                  </a>
                </li>
              </ul>
            </div>
            <div style={{ flex: 0.2 }}></div>
            <div className="service-info-right text-white">
              <h2 className="text-black">Information</h2>
              <div className="info-card">
                {tab == "capital" && (
                  <ul className="info-ul">
                    <li className="df-">
                      <span>RTEE </span>{" "}
                      <span className="text-fullform">
                        {" "}
                        Right Time Equity Entry
                      </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>RTOE </span>{" "}
                      <span className="text-fullform">
                        Right Time Option Entry
                      </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>SMS </span>{" "}
                      <span className="text-fullform">
                        {" "}
                        Stock Management System
                      </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>FDR </span>{" "}
                      <span className="text-fullform"> Fixed Deposit</span>
                    </li>
                  </ul>
                )}

                {tab == "insurance" && (
                  <ul className="info-ul">
                    <li className="df-">
                      <span>Health Insurance Service </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>Life Insurance Service </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>Motor Insurance Service </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>Business Insurance Service </span>
                    </li>
                    {/* <hr /> */}
                  </ul>
                )}
                {tab == "mutual" && (
                  <ul className="info-ul">
                    <li className="df-">
                      <span>Lumpsum Investment </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>SIP Investment </span>
                    </li>
                    <hr />
                  </ul>
                )}
                {tab == "edu" && (
                  <ul className="info-ul">
                    <li className="df-">
                      <span>Technical Analysis </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>Fundamental Analysis </span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>Future And Option Analysis</span>
                    </li>
                    <hr />
                    <li className="df-">
                      <span>Option Strategies </span>
                    </li>
                  </ul>
                )}
                {tab == "other" && (
                  <ul className="info-ul">
                    <li className="df-">
                      <span>Free Cost Demat Account Opening</span>
                    </li>
                    <hr />
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get-expert text-white">
        <h3>
          Get Expert Financial Advice <br />
          Because Experts Knows the best
        </h3>
        <p>
          Purchase of any Financial Products without consulting a financial
          Planner is like taking medicine without prescription .
        </p>
        <button className="button" onClick={()=>scroll("contact")}> Get Started</button>
      </div>
      <div className="why-us" id="why-us">
        <div className="why-us-heading">
          <h3>Why Us?</h3>
        </div>
        <div className="why-us-content">
          <div className="">
            <center className="vedic-image">
              <img src={Vedic} alt="Vedic" />
            </center>
            {/* <div className="vedic-white"></div> */}
          </div>
          <div className="why-us-tiles">
            <div className="tile">
              <p className="text-white">Client Centric Approach</p>
              <p className="text-white tile-sub-heading">
                All our offerings are designed keeping you in mind. Your
                long-term interests serve as the ‘primary’ influencing factor in
                our recommendations process.
              </p>
              <div className="round-icon"></div>
            </div>
            <div className="tile">
              <p className="text-white">Rich Product Basket</p>
              <p className="text-white tile-sub-heading">
                We offer a single point access to multiple financial products
                with a holistic need-driven approach & not product centric
                approach.
              </p>
              {/* <div className="round-icon"></div> */}
            </div>
            <div className="tile">
              <p className="text-white">Focus on Customer Satisfaction</p>
              <p className="text-white tile-sub-heading">
                We come equipped with the right attitude, people, processes and
                technology to ensure higher levels of satisfaction and service
                quality.
                {/* In transactions, we work systematically to find & ensure quick resolution of any queries or complaints. */}
              </p>
              {/* <div className="round-icon"></div> */}
            </div>
          </div>
          <div className="why-us-tiles">
            <div className="tile">
              <p className="text-white">Value Added Services</p>
              <p className="text-white tile-sub-heading">
                Our Services ensure comfort, convenience, confidence and control
                to you in managing your wealth. You would enjoy being always
                updated of your investments, any time, anywhere.
              </p>
              {/* <div className="round-icon"></div> */}
            </div>
            <div className="tile">
              <p className="text-white">Right Experience & Skills</p>
              <p className="text-white tile-sub-heading">
                We have over 30+ years of experience in financial advisory and
                products distribution space.
                {/* Our experience, qualifications & skills enable us to understand you and your needs, and then offer you the right strategies & solutions to achieve your goals. */}
              </p>
              {/* <div className="round-icon"></div> */}
            </div>
            <div className="tile">
              <p className="text-white">Driven by Passion</p>
              <p className="text-white tile-sub-heading">
                Serving clients is our passion and the reason why are in the
                business. Nothing excites us more that helping our clients
                achieve their financial goals and dreams in life with our
                support.
              </p>
              {/* <div className="round-icon"></div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mission text-white" id="mission">
        <div className="mission-heading">
          <h3>Mission Of Vedic Investment Advisory </h3>
          <p>
          Our company motive is to use knowledge and expertise to construct personalized financial plans that aim to achieve the financial goals of clients. These plans include not only investments but also savings, budget, insurance, and tax strategies.
          </p>
          <button className="white-button">Contact Us</button>
        </div>
        <img src={Mission} alt="Mission" className="mission-img" />
      </div> */}
      <div className="new-mission-vision-cont" id="mission">
        <h1>Mission Of Vedic Investment Advisory</h1>
        <p>
          Our company motive is use knowledge and expertise to construct
          personalized financial plans that aim to achieve the financial goals
          of clients. These plans include not only investments but also savings,
          budget, insurance, and tax strategies.
        </p>
        <div className="mission-cta">
          <button className="white-button" onClick={()=>scroll("contact")}>Contact Us</button>
        </div>
        <div className="mission-cont-img">
          <img src={Mission} alt="" />
        </div>
      </div>
      <div className="channel-partners" id="partners">
        <h3> Digital Partners</h3>
        <div className="channel-images">
          <img src={LIC} alt="Channel" />
          <img src={Upstox} alt="Channel" />
          <img src={AngelOne} alt="Channel" />
          <img src={Star} alt="Channel" />
          <img src={Digit} alt="Channel" />
        </div>
      </div>
      <Contact />
      {/* <div className="newsletter" id="socials">
        <div className="news-div df-jcsa text-white">
          <div className="join-us">
            <div className="data">
              <h2>Join Us now!👋 </h2>
              <p>
                Get NFT frops, whale activities, and updates right to you.
                Be the first to hear about breaking news!
              </p>
            </div>
            <div className="input-holder">
              <input placeholder="Enter Your Email" />
              <button className="button">Subscribe</button>
            </div> 
          </div>
          <div className="social-cards df-jcsa text-black">
            <a
              href="https://t.me/vedicinvestment"
              target={"_blank"}
              className="sc-card"
            >
              <div className="social-img">
                <img src={Telegram} alt="icon" />
              </div>
              <h4>Telegram</h4>
              <p>
                Follow us on Telegram for the latest updates on industry news{" "}
              </p>
            </a>
            <a
              href="https://www.instagram.com/vedicinvestmentadvisory/?hl=en"
              target={"_blank"}
              className="sc-card"
            >
              <div className="social-img">
                <img src={Instagram} alt="icon" />
              </div>
              <h4>Instagram</h4>
              <p>
                Follow us on Instagram for the latest updates on industry news{" "}
              </p>
            </a>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Index;
