import React from 'react';
import { useNavigate } from 'react-router';
import Logo from "../img/logo.svg"

const Footer = () => {

    const navigate = useNavigate();
  return (
    <>
        <div className="footer df-jcsb">
            <div className="vedic-logo">
                <div className="logo-and-data">
                    <img src={Logo} alt="Vedic Investment Advisory" className='logo-footer' />
                    <p>The information provided from any source under Vedic Investment Advisory is just for education purpose only and Vedic Investment Advisory will not be responsible for any loss.</p>
                </div>
                <p className='copyright'>Copyright © 2022 Vedic Investment Advisory</p>
            </div>
            <div className="navigations-holder">
                <div className="navigations">
                    <p>Reach Us</p>
                    <ul className='react-ul '>
                        <li className='text-blue'>
                            <a href="https://twitter.com/vedic_advisory" target={"_blank"} className='text-blue' >Twitter</a>
                        </li>
                        <li className='text-blue'>
                            <a  href="https://www.instagram.com/vedicinvestmentadvisory/?hl=en" target={"_blank"} className='text-blue' >Instagram</a>
                        </li>
                        <li className='text-blue'>
                            <a href="https://t.me/vedicinvestment" target={"_blank"} className='text-blue' >Telegram</a>
                        </li>
                        <li className='text-blue'>
                            <a href="https://www.youtube.com/channel/UCJv7dLV4vO7v_0reGRRHLCg" target={"_blank"} className='text-blue' >Youtube</a>
                        </li>
                    </ul>
                </div>
                <div className="navigations">
                    <p>Navigate</p>
                    <ul className='react-ul'>
                        <li className='text-blue'>
                            <a href="javascript:;" onClick={()=>navigate('/')} className='text-blue' >Home</a>
                        </li>
                        <li className='text-blue'>
                            <a href="#why-us" className='text-blue' >Why Us</a>
                        </li>
                        <li className='text-blue'>
                            <a href="#mission" className='text-blue' >Mission</a>
                        </li>
                        <li className='text-blue'>
                            <a href="#partners" className='text-blue' >Partners</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <p className='copyright-phn'>Copyright © 2022 Vedic Investment Advisory</p>
    </>
  )
}

export default Footer