import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Logo from "../img/logo.svg";

const Navbar = () => {
	const navigate = useNavigate();
	const scroll = (id) => {
		document.getElementById(id).scrollIntoView({ behavior: "smooth" });
	}
  return (
    <div className="navbar">
        <img src={Logo} alt="logo" className='logo' onClick={()=>navigate("/")}/>
        <div className="quick-links">
          	<Link to="/about">About</Link>  
          	<Link to="/" onClick={()=>scroll("why-us")}>Why us</Link>  
          	<Link to="/" onClick={()=>scroll("services")}>Services</Link>  
          	<Link to="/" onClick={()=>scroll("partners")}>Partners</Link>  
        </div>
        <div className="quick-phn-links">
          	<Link to="/about">About</Link>  
          	<Link to="/" onClick={()=>scroll("why-us")}>Why us</Link>  
          	<Link to="/" onClick={()=>scroll("services")}>Services</Link>  
          	<Link to="/" onClick={()=>scroll("partners")}>Partners</Link>  
        </div>
        <button className="button contact-top" style={{height:50}} onClick={()=>scroll("contact")}>
            Contact us
        </button>
    </div>
  )
}

export default Navbar