import React, { useEffect } from "react";
import { Routes, Route,Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./pages/index";
import About from "./pages/About/index";

const routes = [
  {
    path: "/",
    exact: true,
    element : <Home />,
    private : false,
  },
  {
    path: "/about",
    exact: true,
    element : <About />,
    private : false,
  },

]



export default function Navigation() {

  const navigate = useNavigate();
  const {loggedIn} = useSelector(state => state.user);

  // useEffect(() => {
  //   if(loggedIn && window.location.pathname === "/"){
  //   }
  //   if(!loggedIn){
  //     navigate("/")
  //   }

  // },[loggedIn,window.location.pathname])



  return (
    <Routes>
      {routes.map((route, index) => (
        <Route 
          key={index} 
          path={route.path} 
          element={
            route.private ? (
              loggedIn ? route.element : (
                window.location.pathname !== "/" && (
                  <Navigate to="/" />
                )
              )
            ) : (route.element)
          } />
      ))}
      {/* <Route path="/" element={<AdminPage />} />
      <Route path="/orders/:id" element={<AdminOrderDetails />} /> */}
    </Routes>
  );
}
