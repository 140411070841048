import logo from "./logo.svg";
import "./App.css";
import Navigation from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
  return (
      <BrowserRouter>
        <ToastContainer />
        <Navigation />
      </BrowserRouter>
  );
}

export default App;
