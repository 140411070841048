import React from "react";
import Footer from "../../component/Footer";
import Navbar from "../../component/Navbar";
import Instagram from "../../img/instagram.png";
import Telegram from "../../img/telegram.svg";
import Lottie from "react-lottie";
import * as animationData from "../../animation/invest.json";
import * as animationData2 from "../../animation/invest2.json";
const About = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="page-wrapper">
      <Navbar />
      <div className="about-page">
        <h1>
          About Vedic <br /> Investment Advisory
        </h1>
        <p>
          We as VEDIC INVESTMENT ADVISORY Financial Consultancy firm at present
          having more than 1000+ satisfied clients. We will help clients to
          achieve their Financial goals with effective and smart investment
          planning. We are certified Wealth Manager, Mutual Fund and Insurance
          consultants recognized by the NSE National Stock Exchange, AMFI
          (Association of Mutual Fund of India).
        </p>
        <div className="about-page-lottie">
          <Lottie options={defaultOptions} height={600} width={600} />
          
        </div>
        <p>
            {" "}
            We as VEDIC INVESTMENT ADVISORY Financial Consultancy firm at
            present having more than 1000+ satisfied clients. We will help
            clients to achieve their Financial goals with effective and smart
            investment planning. We are certified Wealth Manager, Mutual Fund
            and Insurance consultants recognized by the NSE National Stock
            Exchange, AMFI (Association of Mutual Fund of India).We as VEDIC
            INVESTMENT ADVISORY Financial Consultancy firm at present having
            more than 1000+ satisfied clients. We will help clients to achieve
            their Financial goals with effective and smart investment planning.
            <br /><br />
            We are certified Wealth Manager, Mutual Fund and Insurance
            consultants recognized by the NSE National Stock Exchange, AMFI
            (Association of Mutual Fund of India).We as VEDIC INVESTMENT
            ADVISORY Financial Consultancy firm at present having more than
            1000+ satisfied clients. We will help clients to achieve their
            Financial goals with effective and smart investment planning. We are
            certified Wealth Manager, Mutual Fund and Insurance consultants
            recognized by the NSE National Stock Exchange, AMFI (Association of
            Mutual Fund of India).
          </p>
          <div className="about-page-lottie">
          <Lottie options={defaultOptions2} height={600} width={600} />
          
        </div>
        <p>
            {" "}
            We as VEDIC INVESTMENT ADVISORY Financial Consultancy firm at
            present having more than 1000+ satisfied clients. We will help
            clients to achieve their Financial goals with effective and smart
            investment planning. We are certified Wealth Manager, Mutual Fund
            and Insurance consultants recognized by the NSE National Stock
            Exchange, AMFI (Association of Mutual Fund of India).We as VEDIC
            INVESTMENT ADVISORY Financial Consultancy firm at present having
            more than 1000+ satisfied clients. We will help clients to achieve
            their Financial goals with effective and smart investment planning.
            <br /><br />
            We are certified Wealth Manager, Mutual Fund and Insurance
            consultants recognized by the NSE National Stock Exchange, AMFI
            (Association of Mutual Fund of India).We as VEDIC INVESTMENT
            ADVISORY Financial Consultancy firm at present having more than
            1000+ satisfied clients. We will help clients to achieve their
            Financial goals with effective and smart investment planning. We are
            certified Wealth Manager, Mutual Fund and Insurance consultants
            recognized by the NSE National Stock Exchange, AMFI (Association of
            Mutual Fund of India).ffective and smart investment planning. We are
            certified Wealth Manager, Mutual Fund and Insurance consultants
            recognized by the NSE National Stock Exchange, AMFI (Association of
            Mutual Fund of India).ffective and smart investment planning. We are
            certified Wealth Manager, Mutual Fund and Insurance consultants
            recognized by the NSE National Stock Exchange, AMFI (Association of
            Mutual Fund of India).
            <br /><br />
            Mutual Fund and Insurance consultants
            recognized by the NSE National Stock Exchange, AMFI (Association of
            Mutual Fund of India).
          </p>
      </div>

      <div className="newsletter" id="socials">
        <div className="news-div df-jcsa text-white">
          <div className="join-us">
            <div className="data">
              <h2>Join Our Newsletters!👋 </h2>
              <p>
                Get NFT frops, whale activities, and updates right to your
                inbox! Be the first to hear about breaking news!
              </p>
            </div>
            <div className="input-holder">
              <input placeholder="Enter Your Email" />
              <button className="button">Subscribe</button>
            </div>
          </div>
          <div className="social-cards df-jcsa text-black">
            <a
              href="https://twitter.com/vedic_advisory"
              target={"_blank"}
              className="sc-card"
            >
              <div className="social-img">
                <img src={Telegram} alt="icon" />
              </div>
              <h4>Telegram</h4>
              <p>
                Follow us on Telegram for the latest updates on industry news{" "}
              </p>
            </a>
            <a
              href="https://www.instagram.com/vedicinvestmentadvisory/?hl=en"
              target={"_blank"}
              className="sc-card"
            >
              <div className="social-img">
                <img src={Instagram} alt="icon" />
              </div>
              <h4>Instagram</h4>
              <p>
                Follow us on Instagram for the latest updates on industry news{" "}
              </p>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
